import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CardMedia, IconButton, Switch, useTheme } from "@mui/material";
import { contactData } from "../../../data/contactData";
import logo from '../../../assets/logo.webp';
import { DarkThemeContext } from "../../../contexts/DarkThemeProvider";
import { useContext } from "react";
import { DarkMode, LightMode } from "@mui/icons-material";

export default function Header() {

    const { changeTheme, isDark } = useContext(DarkThemeContext);

    const theme = useTheme();

    console.log(isDark);

    const styles = {
        header: {
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '4.625rem',
            position: 'fixed',
            bgcolor: '#000',
            zIndex: 2000
        },
        logo: {
            height: '100%',
            width: {
                xs: '8rem',
                sm: '10rem'
            }
        },
        content: {
            width: '100%',
            maxWidth: '1280px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: {
                xs: '0 1rem',
                sm: '0 2rem',
                md: '0 3rem'
            },
        },
        boxButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
        },
        button: {
            color: theme.palette.common.white,
            display: {
                xs: 'none',
                md: 'block'
            },
            transition: {
                xs: 'none',
                sm: '.5s'
            },
            mr: '33px',
            '&:hover': {
                transition: {
                    xs: 'none',
                    sm: '.5s'
                },
                color: theme.palette.secondary.main,
            }
        },
        themeButton: {
            display: {
                xs: 'none',
                md: 'flex'
            },
            justifyContent: 'center',
            alignItems: 'center',
            mr: '33px',
        }
    };

    const ThemeButton = () => {
        return (
            <IconButton sx={styles.themeButton} color="secondary" onClick={() => changeTheme()}>
                {
                    isDark ? <LightMode /> : <DarkMode />
                }
            </IconButton>)
    }

    return (
        <Box sx={styles.header}>
            <Box sx={styles.content}>
                <Button onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }}>
                    <CardMedia sx={styles.logo} component={'img'} image={logo} alt="Logo Hebert Pereira 21" />
                </Button>
                <Box sx={styles.boxButton}>
                    <ThemeButton />
                    <IconButton sx={styles.button} href={contactData[2].url} target="_blank">
                        <FontAwesomeIcon icon={faInstagram} />
                    </IconButton>
                    <IconButton sx={styles.button} href={contactData[1].url} target="_blank">
                        <FontAwesomeIcon icon={faFacebook} />
                    </IconButton>
                    <IconButton sx={styles.button} href={contactData[0].url} target="_blank">
                        <FontAwesomeIcon icon={faTiktok} />
                    </IconButton>
                    <Button color="secondary" href={contactData[3].url} target="_blank">
                        Contato
                    </Button>
                </Box>
            </Box>
        </Box>
    )
};