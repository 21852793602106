import { Box, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";

export default function Content() {

    const data = sectionsData.content;

    const theme = useTheme();

    const styles = {
        titleBox: {
            width: '92vw',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        content: {
            mt: '1rem',
            borderBottom: `1.5px solid ${theme.palette.secondary.main}`
        },
        body2: {
            mt: {
                xs: '1.75rem',
                md: '2.625rem'
            },
            mb: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        description: {
            mb: '3rem'
        },
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body2}>
                {data.description}
            </Typography>
            <Grid container>
                {
                    data.content.map((card, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={4} md={3}>
                                <Box sx={styles.content}>
                                    <Typography variant="body2" color='secondary' fontWeight='300' fontSize={'2.5rem'}>
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Box mt={'1rem'}>
                                    {
                                        card.items.map((item, index) => {
                                            return (
                                                <Typography variant="body2" fontSize={'1rem'} key={index}>
                                                    {'• ' + item}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
};