import { ThemeProvider } from "@mui/material";
import Layout from "./components/layout";
import Router from "./routes";
import { useContext } from "react";
import { DarkThemeContext } from "./contexts/DarkThemeProvider";

function App() {

  const { chosenTheme } = useContext(DarkThemeContext);

  return (
    <ThemeProvider theme={chosenTheme()}>
      <Layout>
        <Router />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
