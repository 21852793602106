import { Box, CardMedia, Grid, Link, Paper, Typography } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLReactParser from "html-react-parser";

export default function Class() {

    const data = sectionsData.class;

    const parser = HTMLReactParser;

    const styles = {
        titleBox: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        grid: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        paper: {
            overflow: 'hidden',
            transition: {
                xs: 'none',
                sm: 'transform .6s'
            },
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            '&:hover': {
                transform: {
                    xs: 'none',
                    md: 'scale(1.05)'
                },
            }
        },
        filter: {
            height: '100%',
            p: '2rem 1.5rem',
            background: 'rgb(0,0,0,.8)',
            transition: {
                xs: 'none',
                sm: '.5s'
            },
            '&:hover': {
                transition: {
                    xs: 'none',
                    sm: '.5s'
                },
            }
        },
        subtitle1: {
            fontWeight: 600,
            m: '1rem 0',
            color: 'white'
        },
        body2: {
            mt: {
                xs: '1.75rem',
                md: '2.625rem'
            },
            mb: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        description: {
            mb: '3rem',
            color: 'white'
        },
        link: {
            textDecoration: 'none',
            mt: '50px'
        }
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body2}>
                {data.description}
            </Typography>
            <Grid container spacing={3}>
                {
                    data.content.map((card, index) => {
                        return (
                            <Grid sx={styles.grid} key={index} item xs={12} sm={6}>
                                <Paper sx={{ ...styles.paper, backgroundImage: `url(${card.image})` }}>
                                    <Box sx={styles.filter}>
                                        <Box fontSize={'50px'}>
                                            <FontAwesomeIcon icon={card.icon} color={"white"} />
                                        </Box>
                                        <Typography component={'p'} variant="subtitle1" sx={styles.subtitle1}>
                                            {card.title}
                                        </Typography>
                                        <Typography sx={styles.description} variant="body2">
                                            {parser(card.description)}
                                        </Typography>
                                        <Link color='secondary' variant="body1" sx={styles.link} target="_blank" href={card.link}>
                                            Agendar aula agora
                                        </Link>
                                    </Box>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>

        </>
    )
};