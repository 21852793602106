
const terms = <>
    Bem-vindo à landing page de divulgação do curso de motovelocidade oferecido pela empresa Hebert S. Pereira Competições Ltda. Antes de prosseguir, pedimos que leia atentamente os seguintes termos de uso que regem o acesso e uso desta página. Ao utilizar este site, você concorda em cumprir e aceitar estes termos em sua totalidade. Se você não concordar com qualquer parte dos termos, recomendamos que não prossiga com o uso da página.
    <br></br>
    <br></br>
    <strong>Uso da Página:</strong>
    <br></br>
    <br></br>
    1.1. A landing page tem como objetivo fornecer informações sobre o curso de motovelocidade oferecido pela Hebert S. Pereira Competições Ltda. Você concorda em utilizar a página apenas para os fins previstos e de acordo com a lei aplicável.
    <br></br>
    1.2. Ao utilizar a página, você concorda em fornecer informações precisas e atualizadas sobre si mesmo, quando solicitado.
    <br></br>
    1.3. É estritamente proibido utilizar a página para qualquer atividade ilegal, difamatória, abusiva, ofensiva, prejudicial, obscena, pornográfica ou que viole os direitos de terceiros.
    <br></br>
    <br></br>
    <strong>Propriedade Intelectual:</strong>
    <br></br>
    <br></br>
    2.1. Todo o conteúdo presente nesta landing page, incluindo, mas não se limitando a textos, imagens, vídeos, logotipos e marcas registradas, é propriedade exclusiva da Hebert S. Pereira Competições Ltda. e estão protegidos pelas leis de direitos autorais e de propriedade intelectual aplicáveis.
    <br></br>
    2.2. Você concorda em não copiar, reproduzir, distribuir, transmitir, exibir, vender, licenciar ou explorar qualquer conteúdo da página para qualquer finalidade, sem o consentimento prévio por escrito da Hebert S. Pereira Competições Ltda.
    <br></br>
    <br></br>
    <strong>Links Externos:</strong>
    <br></br>
    <br></br>
    3.1. A página pode conter links para sites de terceiros que não são controlados pela Hebert S. Pereira Competições Ltda. Não nos responsabilizamos pelo conteúdo, precisão ou segurança desses sites. A inclusão de qualquer link não implica em nossa aprovação ou endosso desses sites.
    <br></br>
    <br></br>
    <strong>Limitação de Responsabilidade:</strong>
    <br></br>
    <br></br>
    4.1. A Hebert S. Pereira Competições Ltda. não se responsabiliza por quaisquer danos diretos, indiretos, incidentes, consequenciais ou punitivos decorrentes do uso ou incapacidade de uso da landing page.
    <br></br>
    4.2. Embora nos esforcemos para manter as informações atualizadas e precisas, não garantimos a exatidão, integridade ou confiabilidade do conteúdo da página. O uso das informações presentes na página é de sua responsabilidade.
    <br></br>
    <br></br>
    <strong>Alterações nos Termos de Uso:</strong>
    <br></br>
    <br></br>
    5.1. A Hebert S. Pereira Competições Ltda. se reserva o direito de modificar estes termos de uso a qualquer momento, sem aviso prévio. Recomendamos que você revise regularmente estes termos para se manter informado sobre quaisquer alterações.
    <br></br>
    <br></br>
    <strong>Disposições Gerais:</strong>
    <br></br>
    <br></br>
    6.1. Estes termos de uso serão regidos pelas leis brasileiras.
    <br></br>
    6.2. Qualquer disputa decorrente ou relacionada a estes termos de uso será submetida à jurisdição exclusiva dos tribunais competentes na cidade de Porto Alegre - Brasil.
    <br></br>
    6.3. Caso qualquer disposição destes termos de uso seja considerada inválida ou inexequível por qualquer motivo, as demais disposições permanecerão em pleno vigor e efeito.
    <br></br>
    6.4. A falha da Hebert S. Pereira Competições Ltda. em exercer ou aplicar qualquer direito ou disposição destes termos de uso não constituirá uma renúncia a tal direito ou disposição.
    <br></br>
    6.5. Estes termos de uso constituem o acordo integral entre você e a Hebert S. Pereira Competições Ltda. em relação ao uso da landing page, substituindo qualquer acordo anterior.
    <br></br>
    <br></br>
    Ao utilizar esta landing page, você reconhece e concorda em cumprir estes termos de uso. Se você não concorda com qualquer parte destes termos, pedimos que não utilize a página. Em caso de dúvidas ou preocupações, entre em contato conosco através dos canais de comunicação fornecidos na página.
</>;

const policy = <>
    Agradecemos por utilizar o website da Hebert S. Pereira Competições Ltda. A privacidade dos nossos usuários é uma prioridade para nós e estamos comprometidos em proteger suas informações pessoais. Esta Política de Privacidade explica como coletamos, usamos, armazenamos e compartilhamos seus dados pessoais ao utilizar nossos serviços.
    <br></br><br></br>
    <strong>Coleta de dados pessoais e uso de cookies:</strong>
    <br></br><br></br>
    Ao utilizar nosso website, podemos coletar as seguintes informações pessoais:
    <br></br><br></br>
    Nome completo;<br />
    Endereço de e-mail;<br />
    Número de telefone;<br />
    <br></br>
    Esses dados são coletados quando você se registra em nosso website, preenche formulários de contato, participa de pesquisas ou interage de alguma forma conosco. Além disso, utilizamos cookies e tecnologias similares para melhorar sua experiência e fornecer um serviço personalizado. Os cookies nos permitem reconhecer e lembrar suas preferências, além de coletar informações sobre sua interação conosco. Utilizamos cookies para entender como você utiliza nosso site, personalizar o conteúdo exibido, fornecer anúncios relevantes e realizar análises para aprimorar nossos serviços.
    <br></br><br></br>
    <strong>Uso e finalidade dos dados:</strong>
    <br></br><br></br>
    Utilizamos os dados pessoais coletados, juntamente com as informações obtidas por meio dos cookies, para as seguintes finalidades:
    <br></br><br></br>
    Comunicar informações relevantes como datas de aula, atualizações de serviços e outras notificações relacionadas aos nossos produtos e serviços.<br />
    Enviar ofertas, promoções ou informações sobre eventos especiais, desde que você tenha dado consentimento prévio.<br />
    Melhorar e personalizar sua experiência em nosso website.<br />
    Realizar análises e pesquisas internas para aprimorar nossos serviços e entender as necessidades dos nossos usuários.<br />
    Cumprir obrigações legais e regulatórias aplicáveis.
    <br></br><br></br>
    <strong>Armazenamento dos dados:</strong>
    <br></br><br></br>
    Nós armazenamos seus dados pessoais de forma segura em nossos servidores pelo período de 6 meses. Após esse período, seus dados serão anonimizados ou excluídos, a menos que exista uma obrigação legal ou regulatória para a retenção dos mesmos.
    <br></br><br></br>
    <strong>Compartilhamento de dados:</strong>
    <br></br><br></br>
    Não compartilhamos seus dados pessoais com terceiros, exceto nas seguintes circunstâncias:
    <br></br><br></br>
    Quando necessário para fornecer os serviços solicitados por você.<br />
    Com o seu consentimento prévio.<br />
    Quando exigido por lei ou em resposta a uma solicitação legal válida.<br />
    Para proteger os direitos, propriedade ou segurança da Hebert S. Pereira Competições Ltda., dos nossos usuários ou de terceiros.
    <br></br><br></br>
    <strong>Segurança dos dados:</strong>
    <br></br><br></br>
    Implementamos medidas técnicas e organizacionais adequadas para proteger seus dados pessoais contra acesso não autorizado, divulgação, alteração ou destruição acidental ou ilegal. No entanto, é importante lembrar que nenhum método de transmissão ou armazenamento eletrônico é 100% seguro.
    <br></br><br></br>
    <strong>Seus direitos:</strong>
    <br></br><br></br>
    Você tem o direito de acessar, corrigir, atualizar ou solicitar a exclusão dos seus dados pessoais. Caso deseje exercer esses direitos ou tenha qualquer dúvida sobre a nossa Política de Privacidade, entre em contato conosco por meio das informações fornecidas abaixo.
    <br></br><br></br>
    <strong>Alterações nesta Política de Privacidade:</strong>
    <br></br><br></br>
    Podemos atualizar esta Política de Privacidade periodicamente para refletir alterações nos nossos serviços ou práticas de privacidade. Recomendamos que você reveja regularmente esta política para se manter informado sobre como proteger suas informações pessoais.
    <br></br><br></br>
    <strong>Contato:</strong>
    <br></br><br></br>
    Se você tiver alguma dúvida, preocupação ou solicitação relacionada à nossa Política de Privacidade, entre em contato conosco através de um dos canais de contato disponibilizados abaixo:
    <br></br><br></br>
    E-mail: contato@hp21.com.br<br />
    Telefone: +55 51 9 9849-0368<br />
    Endereço: R. Canela, 249 - Ouro Branco, Novo Hamburgo<br />
    <br></br><br></br>
    Agradecemos por ler nossa Política de Privacidade. Ao utilizar nosso website, você concorda com a coleta, uso e armazenamento dos seus dados pessoais de acordo com os termos aqui descritos. Estamos empenhados em proteger sua privacidade e garantir a segurança das suas informações.
    <br></br><br></br>
    Esperamos que esta Política de Privacidade tenha esclarecido suas dúvidas sobre como lidamos com suas informações pessoais. Caso você tenha alguma pergunta adicional ou precise de mais informações, por favor, não hesite em entrar em contato conosco pelos canais de comunicação fornecidos acima.
    <br></br><br></br>
    <strong>Data de vigência: 05 de junho de 2023.</strong>
    <br></br><br></br>
    Atenciosamente,
    <br></br><br></br>
    Hebert S. Pereira Competições Ltda.
    Equipe de Privacidade.
    <br></br><br></br>
    <strong>Informações da Empresa:</strong>
    <br></br><br></br>
    Nome: Hebert S. Pereira Competições Ltda.<br />
    CNPJ: 22.685.020/0001-86<br />
    Sede: R. Canela, 249 - Ouro Branco, Novo Hamburgo.
    <br></br><br></br>
</>;

export const termsPolicy = {
    terms: terms,
    policy: policy
};