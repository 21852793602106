import { faFacebook, faInstagram, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export const contactData = [
    {
        icon: faTiktok,
        title: 'TikTok',
        url: 'https://www.tiktok.com/@hebertpereira21'
    },
    {
        icon: faFacebook,
        title: 'Facebook',
        url: 'https://www.facebook.com/hebertracing'
    },
    {
        icon: faInstagram,
        title: 'Instagram',
        url: 'https://www.instagram.com/hebertpereira21/'
    },
    {
        icon: faWhatsapp,
        title: 'Whatsapp',
        url: 'https://wa.me/5551998490368?text=Olá Hebert, tudo bem? Estou interessado nos curso de pilotagem!'
    }
];