import { Box } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";

export default function Layout({ children }) {

    const styles = {
        layout: {
            minHeight: '100vh'
        }
    };

    return (
        <Box sx={styles.layout} >
            <Header />
            {children}
            <Footer />
        </Box>
    )
};