import { faPeopleGroup, faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';

import image0 from '../assets/teacher/0.webp';
import image1 from '../assets/teacher/1.webp';
import image2 from '../assets/teacher/2.webp';

import progress1 from '../assets/progress/1.webp';
import progress2 from '../assets/progress/2.webp';
import progress3 from '../assets/progress/3.webp';
import progress4 from '../assets/progress/4.webp';
import progress5 from '../assets/progress/5.webp';
import progress6 from '../assets/progress/6.webp';
import progress7 from '../assets/progress/7.webp';
import progress8 from '../assets/progress/8.webp';

import individual from '../assets/class/Individual.webp';
import openGroup from '../assets/class/OpenGroup.webp';

import feedback1 from '../assets/feedback/feedback1.webp';
import feedback2 from '../assets/feedback/feedback2.webp';
import feedback3 from '../assets/feedback/feedback3.webp';
import feedback4 from '../assets/feedback/feedback4.webp';
import feedback5 from '../assets/feedback/feedback5.webp';
import feedback6 from '../assets/feedback/feedback6.webp';
import feedback7 from '../assets/feedback/feedback7.webp';
import feedback8 from '../assets/feedback/feedback8.webp';
import feedback9 from '../assets/feedback/feedback9.webp';
import feedback10 from '../assets/feedback/feedback10.webp';
import feedback11 from '../assets/feedback/feedback11.webp';

import placeholder from '../assets/instagram/placeHolder.webp';

const sectionsData = {
    welcome: {
        title: "Curso com o 10x <br> campeão gaúcho, <br> <span style='color: #00E917'>Hebert Pereira.<span/> ",
        description: "Eleve seu aprendizado com aulas projetadas para capacitar motociclistas de todos os níveis, seja no trânsito, nas estradas ou nas pistas."
    },
    class: {
        title: "AULAS",
        description: "Participe de um curso aberto para aprender em conjunto com outros parceiros de pilotagem ou aproveite uma abordagem personalizada e exclusiva do curso VIP, onde você terá uma aula focada em acentuar seus pontos fortes e lapidar seus pontos fracos.",
        content: [
            {
                image: openGroup,
                icon: faPeopleGroup,
                title: 'CURSO EM GRUPO',
                description: "Dinâmicas em grupos de até 16 alunos, onde se aplica o conteúdo da aula enquanto também se desenvolve o interesse individual dos alunos.<br><strong>• Moto, kartódromo e macacão já incluídos!<strong/>",
                link: 'https://api.whatsapp.com/send?phone=5551998490368&text=Ol%C3%A1%20Hebert,%20tudo%20bem?%20Gostaria%20de%20saber%20mais%20sobre%20seu%20curso%20em%20grupo!'
            },
            {
                image: individual,
                icon: faUser,
                title: 'CURSO VIP',
                description: 'Dia exclusivo com Hebert Pereira na pista, somente você e o instrutor. Essa oportunidade única permite um aprendizado mais focado nas necessidades individuais, podendo ser realizada com no máximo dois alunos. <br><strong>• Datas pré estabelecidas!<strong/>',
                link: 'https://api.whatsapp.com/send?phone=5551998490368&text=Ol%C3%A1%20Hebert,%20tudo%20bem?%20Gostaria%20de%20saber%20mais%20sobre%20seu%20curso%20VIP!'
            },
        ]
    },
    content: {
        title: "CONTEÚDO",
        description: "Disponibilizamos por tempo limitado um treinamento totalmente personalizado, com enfoque em aprimorar as seguintes técnicas de pilotagem:",
        content: [
            {
                title: 'Postura.',
                items: [
                    "Postura de reta",
                    "Postura de frenagem",
                    "Pêndulo de meio de curva",
                    "Pêndulo de saída de curva",
                    "Pêndulo de transição"
                ]
            },
            {
                title: 'Freios.',
                items: [
                    'Uso de freio dianteiro',
                    'Uso de freio traseiro',
                    'Posicionamento do corpo',
                    'Contra-esterço'
                ]
            },
            {
                title: 'Visão Geral.',
                items: [
                    'Traçado',
                    'Referência',
                    'Campo de visão',
                    'Tangência'
                ]
            },
            {
                title: 'Exercícios.',
                items: ['Iremos trabalhar diferentes exercícios personalizados para o interesse do aluno']
            },
        ]
    },
    faq: {
        title: "FAQs",
        description: "Aqui estão algumas das perguntas mais comuns feitas pelos nossos alunos!",
        faqs: [
            {
                ask: "Onde?",
                answer: 'O curso é realizado no kartódromo de Bento Gonçalves, Rio Grande do Sul'
            },
            {
                ask: "Quando?",
                answer: 'O curso em grupo ocorre em finais de semana e feriados. Já o curso VIP acontece durante dias úteis na semana.'
            },
            {
                ask: "Como funciona o dia?",
                answer: 'O dia no curso HP21 inicia às 8h30 e termina às 15h30, sendo um dia intensivo de pilotagem em que vamos treinar todas as técnicas citadas acima na sessão Conteúdo.'
            },
            {
                ask: "E se chover?",
                answer: 'Remarcamos para o próximo dia de curso disponível sem custo algum.'
            },
            {
                ask: "A escola disponibiliza equipamentos para o dia de curso?",
                answer: 'HP21 disponibiliza equipamentos para o dia do curso. As motos de pista e o kartódromo estão inclusos no valor do curso e fornecemos macacão para os alunos que não têm um próprio.'
            },
        ],
        noFaq: {
            link: '',
            title: "Não encontrou sua dúvida?",
            description: 'Clique aqui para ser redirecionado para o nosso canal de comunicação no Whatsapp.'
        }
    },
    progress: {
        title: "ANTES E DEPOIS",
        description: "A postura da curva é uma das transformações mais marcantes que observamos nos primeiros momentos da aula dos nossos alunos.",
        description1: "Apresentamos abaixo uma coleção de imagens ilustrando o progresso alcançado pelos nossos estudantes:",
        content: [
            {
                student: progress1,
            },
            {
                student: progress5,
            },
            {
                student: progress2,
            },
            {
                student: progress3,
            },
            {
                student: progress4,
            },
            {
                student: progress6,
            },
            {
                student: progress7,
            },
            {
                student: progress8,
            },
        ]
    },
    feedbacks: {
        title: "DEPOIMENTOS",
        description: "O nosso curso é reconhecido por proporcionar resultados reais e imediatos para todos os pilotos, com uma notável melhoria na pilotagem já no início do dia.",
        description1: "Acompanhe abaixo alguns relatos inspiradores de alunos que passaram pela nossa formação:",
        content: [
            {
                description: '"Curso de pilotagem HP21, @hebertpereira21 sabendo como cheguei no curso e vendo como sai, a evolução foi notável, agora só treinar mais e mais pra gradativamente melhorar."',
                icon: feedback1,
                name: '@thiago.1984',
            },
            {
                description: '"Ontem foi um dia de muito aprendizado, temos muito a evoluir ainda, mas cada dica e centavo pago posso afirmar que valeu muito a pena. Quem ainda não garantiu seu curso não perde tempo e chama logo o mestre @hebertpereira21."',
                icon: feedback2,
                name: '@beap_ninja300',
            },
            {
                description: '"Aquele medo que eu tinha me saiu como um piscar de olhos. Curvas que antigamente eu freava na entrada e agora entro lançado, com embalo da moto. Posicionava e já saia dando mão. Sensacional a evolução que um bom posicionamento pode te dar"',
                icon: feedback11,
                name: '@junior.rrsilva',
            },
            /* {
                description: '"Grande professor, obrigado pelos ensinamentos. Consegui evoluir em alguns pontos que tinha dificuldade, agora é focar e aplicar na pista tudo o que aprendi."',
                icon: feedback11,
                name: '@jeefabinho',
            }, */
            {
                description: '"Quer aprender a usar tua moto de forma segura e com confiaçã? @Hebertpereira21"',
                icon: feedback3,
                name: '@89.anderson',
            },
            {
                description: '"Curso de pilotagem com resultados de verdade!!! Com ele a gente aprende na prática!"',
                icon: feedback4,
                name: '@cris.castilhos',
            },
            {
                description: '"Muito obrigado pela paciência, pois havia muitas "manias" a serem trabalhadas... E aos amigos pilotos ou que gostam de motociclismo, indico MESMO o curso do Hebert, VALE CADA CENTAVO! São ensinamentos que podem SALVAR a sua VIDA!"',
                icon: feedback5,
                name: '@gabrielsiqueira.oficial',
            },
            {
                description: '"Acreditem, uma instrução adequada é essencial. Parabéns @hebertpereira21, que curso! Valeu demais cada dica. E ah, valeu pela primeira slide de joelho ter sido contigo!"',
                icon: feedback6,
                name: '@juniorrodriguesrs',
            },
            {
                description: '"Sem dúvida o melhor professor e a melhor equipe/equipamentos! Já fiz e vou fazer mais, e recomendo!"',
                icon: feedback7,
                name: '@girl_ontwowheels',
            },
            {
                description: '"O começo de uma constante evolução; Não tenho palavras para descrever tamanha alegria. Créditos ao grande piloto e professor @hebertpereira21 por toda dedicação aos seus alunos."',
                icon: feedback8,
                name: '@martinss.fernandaa',
            },
            {
                description: '"Um final de semana fantástico. Com muito aprendizado e novas amizades. Obrigado @hebertpereira21 pela paciência e didática fantástica para ensinar a arte de pilotar."',
                icon: feedback9,
                name: '@filipeonorata',
            },
            {
                description: '"HP21 curso que te tira da zona de conforto!! Só agradecer a parceria, esforço, dedicação e o empenho para cada aluno (independente de quem for) para chegar na sua meta!!"',
                icon: feedback10,
                name: '@gui.soares911',
            },
        ]
    },
    coaching: {
        title: "Já é aluno da HP21?",
        subtitle: "Faça seu coaching em autódromo",
        description: "Apenas alunos HP21 estão habilitados a realizar o coaching em autódromo com Hebert Pereira e sua moto particular"
    },
    teacher: {
        title: "INSTRUTOR",
        description: "Hebert Pereira.",
        description1: "Piloto profissional que acumula mais de 15 anos de experiência no esporte com a impressionante conquista de ser o 10x Campeão Gaúcho.",
        description2: "Hoje compartilha na HP21 seu conhecimento e habilidades com uma ampla gama de alunos, desde iniciantes até pilotos experientes, bem como alunos de peso como a corporação da Brigada Militar, Guarda Municipal e Exército do Rio Grande do Sul.",
        content: {
            image0: image0,
            image1: image1,
            image2: image2
        }
    },
    instagram: {
        title: "INSTAGRAM",
        description: "Conheça o trabalho de Hebert Pereira nas redes sociais e aprenda valiosas dicas e conteúdos relacionados ao mundo da pilotagem.",
        placeholder: placeholder,
    },
};

export default sectionsData;