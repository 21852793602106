import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import welcomeBg from '../../../assets/welcomeBg.svg';
import welcomeBgMobile from '../../../assets/welcomeBg.webp';
import { contactData } from "../../../data/contactData";
import sectionsData from "../../../data/sectionsData";
import HTMLReactParser from "html-react-parser";

export default function Welcome() {

    const contact = contactData[3].url;

    const theme = useTheme();

    const contentData = sectionsData.welcome;

    const parser = HTMLReactParser;

    const styles = {
        content: {
            m: '1rem 0',
            backgroundImage: {
                xs: 'none',
                md: `url(${welcomeBg})`
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: {
                md: '-550px',
                lg: '-650px',
                xl: '-1050px'
            },
            height: {
                md: '38rem',
                lg: '45rem',
                xl: '65rem'
            },
            p: {
                md: '2.5rem 3rem'
            },
            display: 'flex',
            justifyContent: 'center',
        },
        container: {
            width: '100%',
            maxWidth: '1280px',
            padding: {
                xs: '0 1rem',
                sm: '0 2rem',
                md: 'none'
            }
        },
        info: {
            width: {
                xs: '100%',
                md: '45%'
            },
        },
        description: {
            mt: '1rem',
            width: '80%',
            bgcolor: theme.palette.secondary.bgBody,
            opacity: 0.8,
            borderRadius: 1
        },
        button: {
            borderRadius: '55px',
            mt: '1.5rem',
        },
        buttonBox: {
            width: '60%',
            display: {
                xs: 'none',
                md: 'block'
            }
        },
        imageBox: {
            overflow: 'hidden',
            backgroundImage: {
                xs: `url(${welcomeBgMobile})`,
                md: 'none'
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: {
                xs: '70%',
            },
            height: {
                xs: '20rem',
                sm: '30rem'
            },
            display: {
                xs: 'flex',
                md: 'none'
            },
        },
        mobileOnly: {
            display: {
                md: 'none'
            },
            padding: {
                xs: '0 1rem',
                sm: '0 2rem',
                md: 'none'
            }
        },
    };

    return (
        <Grid container sx={styles.content}>
            <Grid item sx={styles.container}>
                <Box sx={styles.info}>
                    <Typography variant="h1">
                        {parser(contentData.title)}
                    </Typography>
                    <Typography sx={styles.description}>
                        {contentData.description}
                    </Typography>
                    <Box sx={styles.buttonBox}>
                        <Button fullWidth color="secondary" sx={styles.button} variant="contained" target="_blank" href={contact}>
                            Entre em contato
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={styles.imageBox}>
            </Grid>
            <Grid item xs={12} sx={styles.mobileOnly}>
                <Button fullWidth color="secondary" sx={styles.button} variant="contained" target="_blank" href={contact}>
                    Entre em contato
                </Button>
            </Grid>
        </Grid>
    )
};