import { Backdrop, Box, Button, Card, Fade, Link, Modal, Typography, useTheme } from "@mui/material";
import background from '../../../assets/footerBg.webp';
import { useState } from "react";
import { termsPolicy } from "../../../data/termsPolicy";

export default function Footer() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();

    const currentYear = new Date().getFullYear();

    const styles = {
        background: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: {
                xs: '45rem',
                lg: '60rem'
            },
        },
        linkColor: {
            color: theme.palette.secondary.main,
            m: '0 1rem'
        },
        bar: {
            height: '3rem',
            bgcolor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        link: {
        },
        modal: {
            position: 'absolute',
            outline: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxHeight: '65vh',
            overflowY: 'scroll',
            bgcolor: 'white',
            p: 4,
        }
    };

    return (
        <>
            <Box sx={styles.background} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Card sx={styles.modal}>
                        <Typography color={'black'} id="transition-modal-title" variant="h4">
                            Termos de Uso:
                        </Typography>
                        <Typography color={'black'} sx={{ mt: 2 }}>
                            {termsPolicy.terms}
                        </Typography>
                        <Typography mt={'2rem'} color={'black'} id="transition-modal-title" variant="h4">
                            Políticas de Privacidade:
                        </Typography>
                        <Typography color={'black'} id="transition-modal-description" sx={{ mt: 2 }}>
                            {termsPolicy.policy}
                        </Typography>
                    </Card>
                </Fade>
            </Modal>
            <Box>
                <Box sx={styles.bar} >
                    <Button m={'1rem'} sx={styles.link} textAlign='center' onClick={handleOpen}>
                        <Typography color={'secondary'} fontWeight={600} variant="body3">
                            Termos de Uso e Políticas de Privacidade
                        </Typography>
                    </Button>
                    <Button m={'1rem'} sx={styles.link} textAlign='center' target="_blank" href="https://pixellb.com.br/">
                        <Typography color={'secondary'} fontWeight={600} variant="body3">
                            © {currentYear} Pixel Lab
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    )
};