import { createTheme } from '@mui/material';

let darkTheme = createTheme({
    typography: {
        fontFamily: 'Public Sans, sans-serif'
    },
    palette: {
        primary: {
            main: '#FFF',
            black: '#000',
        },
        secondary: {
            main: '#00E917',
            background: '#2B2B2B',
            bgBody: '#000',
            bgOpposite: '#FFF'
        }
    },
});

darkTheme = createTheme(darkTheme, {
    typography: {
        h1: {
            color: darkTheme.palette.primary.main,
            fontFamily: 'Anton, Sans-serif',
            fontSize: '2.25rem',
            [darkTheme.breakpoints.up('sm')]: {
                fontSize: '3.25rem',
            }
        },
        h2: {
            color: darkTheme.palette.primary.main,
            fontFamily: 'Anton, Sans-serif',
            fontSize: '2.25rem',
            lineHeight: '2.25rem',
            [darkTheme.breakpoints.up('sm')]: {
                fontSize: '3rem',
            }
        },
        h3: {
            fontWeight: 500,
            fontFamily: 'Anton, Sans-serif',
            fontSize: '1.625rem',
            [darkTheme.breakpoints.up('md')]: {
                fontSize: '2.125rem',
            }
        },
        subtitle1: {
            color: darkTheme.palette.primary.main,
            fontWeight: 300,
        },
        body1: {
            color: darkTheme.palette.primary.main,
            fontSize: '.875rem',
            [darkTheme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
            }
        },
        body2: {
            color: darkTheme.palette.primary.main,
            fontSize: '.875rem',
        },
        body3: {
            fontFamily: 'Public Sans, sans-serif',
            color: darkTheme.palette.primary.main,
            fontSize: '.75rem',
        },
        button: {
            fontWeight: 700,
            textTransform: 'none',
            [darkTheme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
            }
        }
    },
    shape: {
        borderRadius: 8
    },
    shadows: Array(25).fill('0px 18px 18px rgba(0, 0, 0, 0.15)')
})

export default darkTheme;