import { Box, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default function Progress() {

    const data = sectionsData.progress;

    const theme = useTheme();

    const styles = {
        titleBox: {
            width: '92vw',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        body1: {
            mt: '1.5rem',
            mb: '1rem'
        },
        body2: {
            mb: {
                xs: '1.5rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        info: {
            mt: {
                xs: '.5rem',
                sm: '1rem'
            },
            fontWeight: 400,
            fontSize: {
                xs: '.75rem',
                sm: '.875rem',
                md: '1rem',

            }
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 1
        },
        swiper: {
            m: 'auto',
            maxWidth: '100vw',
            '& .swiper-pagination-bullet-active': {
                bgcolor: theme.palette.secondary.main,
            },
            '& .swiper-pagination-bullet': {
                bgcolor: `${theme.palette.secondary.main}FF`
            },
            '& .mySwiper': {
                width: '100%',
            }
        }
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body1}>
                {data.description}
            </Typography>
            <Typography variant="body1" sx={styles.body2}>
                {data.description1}
            </Typography>
            <Grid item spacing={3} xs={12} md={7} sx={styles.swiper} >
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                    navigation={true}
                    className="mySwiper"
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                    }}
                >
                    {
                        data.content.map((obj, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <CardMedia component="img" image={obj.student} sx={styles.image} alt="Imagem comparativa de antes e depois do curso" />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Grid>
        </>
    )
};