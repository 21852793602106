import { Box, CardMedia, Grid, Typography } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";

export default function Teacher() {

    const data = sectionsData.teacher;

    const styles = {
        titleBox: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        body1: {
            mt: '1.5rem',
            mb: '1rem'
        },
        body2: {
            mb: {
                xs: '1.5rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        images: {
            borderRadius: 1,
            height: {
                xs: '8rem',
                sm: '14rem',
                md: '29rem'
            },
            width: '100%'
        },
        imagesMain: {
            borderRadius: 1,
            height: {
                xs: '16.5rem',
                sm: '28.5rem',
                md: '58.5rem'
            },
            width: '100%'
        }
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" color={'secondary'} sx={styles.body1}>
                {data.description}
            </Typography>
            <Typography variant="body1" sx={styles.body1}>
                {data.description1}
            </Typography>
            <Typography variant="body1" sx={styles.body2}>
                {data.description2}
            </Typography>
            <Grid container spacing={1} display={'flex'} flexWrap='wrap'>
                <Grid item xs={6}>
                    <CardMedia component="img" image={data.content.image0} sx={styles.imagesMain} alt="Imagem de uma aula com o professor Hebert Pereira" />
                </Grid>
                <Grid item xs={6}>
                    <Grid container rowGap={'.5rem'}>
                        <Grid item xs={12}>
                            <CardMedia component="img" image={data.content.image1} sx={styles.images} alt="Imagem de uma aula com o professor Hebert Pereira" />
                        </Grid>
                        <Grid item xs={12}>
                            <CardMedia component="img" image={data.content.image2} sx={styles.images} alt="Imagem de uma aula com o professor Hebert Pereira" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};