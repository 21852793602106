import { Box, Button, Typography } from "@mui/material";
import coachingBg from '../../../assets/coachingBg.webp';
import sectionsData from "../../../data/sectionsData";
import { RocketLaunch } from "@mui/icons-material";
import { Parallax } from "react-parallax";

export default function Coaching() {

    const content = sectionsData.coaching;

    const contact = "https://api.whatsapp.com/send/?phone=5551998490368&text=Ol%C3%A1+Hebert%2C+tudo+bem%3F+J%C3%A1%20realizei%20curso%20e%20gostaria%20de%20participar%20do%20seu%20curso%20exclusivo%20de%20Coaching!";

    const styles = {
        content: {
            bgcolor: 'rgb(0,0,0,.5)',
            p: '3rem 0rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        container: {
            ml: '1rem',
            width: '100vw',
            maxWidth: '1280px',
        },
        description: {
            color: 'white',
            mt: '1rem',
            width: '80%'
        },
        title: {
            fontSize: '2.5rem!important'
        },
        button: {
            mt: '1rem',
            borderRadius: 3,
        }
    };

    return (
        <Parallax bgImage={coachingBg} bgImageAlt="Hebert em sua moto particular" blur={3} strength={100} >
            <Box sx={styles.content}>
                <Box sx={styles.container}>
                    <Typography sx={styles.title} color={'white'} variant="h2">
                        {content.title}
                    </Typography>
                    <Typography sx={styles.title} variant="h2" color={'secondary'} mt='.5rem'>
                        {content.subtitle}
                    </Typography>
                    <Typography variant="body2" sx={styles.description}>
                        {content.description}
                    </Typography>
                    <Button startIcon={<RocketLaunch fontSize="3rem" color={'black'} />} sx={styles.button} variant="contained" color="secondary" target="_blank" href={contact}>
                        <Typography color={'black'} fontWeight={600}>
                            Agendar agora
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Parallax>
    )
};