import { Box, CardMedia, Grid, Paper, Typography, useTheme } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default function Feedbacks() {

    const theme = useTheme();

    const data = sectionsData.feedbacks;

    const styles = {
        titleBox: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        swiper: {
            '& .swiper-pagination-bullet-active': {
                bgcolor: theme.palette.secondary.main,
            },
            '& .swiper-pagination-bullet': {
                bgcolor: `${theme.palette.secondary.main}FF`
            },
        },
        paper: {
            height: '9rem',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            bgcolor: theme.palette.secondary.background,
            p: '1.5rem 1rem'
        },
        body1: {
            mt: '1.5rem',
            mb: '1rem'
        },
        body2: {
            mb: {
                xs: '1.5rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        userImage: {
            height: '2rem',
            width: '2rem',
            borderRadius: '50%'
        },
        feedbackBox: {
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'row',
            mt: '1rem'
        },
        feedBackContent: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column'
        },
        feedbackName: {
            fontWeight: 700
        },
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body1}>
                {data.description}
            </Typography>
            <Typography variant="body1" sx={styles.body2}>
                {data.description1}
            </Typography>
            <Box sx={styles.swiper}>
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        900: {
                            slidesPerView: 3,
                        },
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                    navigation={true}
                    className="mySwiper"
                    slidesPerView={3}
                    spaceBetween={30}
                    autoplay={{
                        delay: 5000,
                    }}
                >
                    {
                        data.content.map((card, index) => {
                            return (
                                <SwiperSlide key={index} className="paper">
                                    <Paper sx={styles.paper}>
                                        <Typography variant="body2" fontStyle={"italic"}>
                                            {card.description}
                                        </Typography>
                                        <Box sx={styles.feedbackBox}>
                                            <CardMedia component="img" image={card.icon} sx={styles.userImage} alt={`Imagem de ${card.name}, aluno da HP21`} />
                                            <Box ml={'.5em'} sx={styles.feedBackContent}>
                                                <Typography variant="body3" sx={styles.feedbackName}>
                                                    {card.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Box>
        </>
    )
};