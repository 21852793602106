import { useState } from "react";
import { createContext } from "react";
import darkTheme from "../styles/darkTheme";
import lightTheme from "../styles/lightTheme";

export const DarkThemeContext = createContext({});

function DarkThemeProvider({ children }) {
    const [isDark, setIsDark] = useState(true);

    function changeTheme() {
        setIsDark(!isDark);
    };

    function chosenTheme() {
        if (isDark) {
            return darkTheme;
        } else {
            return lightTheme;
        };
    };

    return (
        <DarkThemeContext.Provider
            value={{
                isDark,
                changeTheme,
                chosenTheme
            }} >
            {children}
        </DarkThemeContext.Provider>
    )
}

export default DarkThemeProvider;