import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Link, Typography, useTheme } from "@mui/material";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";
import { ArrowForward, ExpandMore } from "@mui/icons-material";
import { contactData } from "../../../data/contactData";

export default function Faq() {

    const theme = useTheme();

    const data = sectionsData.faq;

    const contact = contactData[3].url;

    const styles = {
        titleBox: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        title: {
            color: theme.palette.secondary.bgBody
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        question: {
            m: '.5rem 0',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center'
        },
        body2: {
            color: theme.palette.secondary.bgBody,
            mt: {
                xs: '1.75rem',
                md: '2.625rem'
            },
            mb: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem'
            }
        },
        titleFaq: {
            color: 'black',
            fontSize: {
                xs: '1rem',
                sm: '1.5rem'
            }
        },
        linkButton: {
            mt: '2rem',
        }
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {data.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body2}>
                {data.description}
            </Typography>

            <Box>
                {data.faqs.map((obj, index) => {
                    return (
                        <Accordion key={index} >
                            <AccordionSummary
                                expandIcon={<ExpandMore color="secondary" />}
                            >
                                <Box sx={styles.question}>
                                    <ArrowForward color="secondary" />
                                    <Typography ml=".5rem" fontWeight={700} variant="body1" sx={styles.titleFaq}>
                                        {obj.ask}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography ml="2rem" color={'black'} variant="body1">
                                    {obj.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Box>
            <Button endIcon={<ArrowForward color="secondary" />} color="secondary" sx={styles.linkButton} variant="outlined" target="_blank" href={contact}>
                <Typography fontWeight="600" color={theme.palette.secondary.bgBody}>
                    Tire outras dúvidas
                </Typography>
            </Button>
        </>
    )
};