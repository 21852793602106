import { Box, Grid, useTheme } from "@mui/material";
import homelist from "../../data/homeList";
import 'swiper/css';

export default function Home() {

    const theme = useTheme();

    function colorVerify(data) {
        if (data) {
            return theme.palette.secondary.bgOpposite;
        } else {
            return theme.palette.secondary.bgBody;
        }
    };

    const styles = {
        body: {
            pt: '4.5rem',
            display: 'flex',
            overflow: 'hidden'
        },
        sections: {
            p: {
                xs: '2.5rem 1rem',
                sm: '2.5rem 2rem',
                md: '2.5rem 3rem'
            },
            display: 'flex',
            justifyContent: 'center'
        },
        container: {
            maxWidth: '1280px'
        }
    };

    return (
        <Box sx={styles.body}>
            <Grid container>
                {

                    homelist.map((obj, index) => {
                        if (index === 0 || index === 6) {
                            return (
                                <Grid component={'section'} key={index} item xs={12} sx={{
                                    bgcolor: colorVerify(obj.opposite)
                                }}>
                                    {obj.content}
                                </Grid>
                            );
                        } else if (index + 1 === homelist.length) {
                            return (
                                <Grid component={'section'} key={index} item xs={12} sx={{
                                    ...styles.sections, bgcolor: "#e8e8e8"
                                }}>
                                    <Box sx={styles.container}>
                                        {obj.content}
                                    </Box>
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid component={'section'} key={index} item xs={12} sx={{
                                    ...styles.sections, bgcolor: colorVerify(obj.opposite)
                                }}>
                                    <Box sx={styles.container}>
                                        {obj.content}
                                    </Box>
                                </Grid>
                            );
                        }
                    })
                }
            </Grid>
        </Box>
    );
};