import { Box, CardMedia, Grid, IconButton, Link, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import sectionsData from "../../../data/sectionsData";
import tire from "../../../assets/tire.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import instaToken from "../../../data/instaToken";
import { contactData } from "../../../data/contactData";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Instagram() {

    const [feedList, setFeedList] = useState([]);

    const contentData = sectionsData.instagram;

    const theme = useTheme();

    async function getInstaFeed() {

        let fields = "media_url, permalink, media_type, thumbnail_url";

        let url = `https://graph.instagram.com/me/media?access_token=${instaToken}&fields=${fields}`;

        const { data } = await axios.get(url);

        let slicedArray = data.data.slice(0, 6);

        setFeedList(slicedArray);
    };

    useEffect(() => {
        getInstaFeed();
    }, []);

    const styles = {
        titleBox: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'end'
        },
        title: {
            color: theme.palette.primary.black
        },
        tire: {
            height: '1rem',
            width: '3.2rem',
            ml: '0.5rem'
        },
        body1: {
            color: theme.palette.primary.black,
            mt: '1.5rem',
            mb: '1rem'
        },
        grid: {
            mt: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3.125rem'
            },
            '& span': {
                height: '100%'
            },
            '& .instagram-image': {
                borderRadius: 1,
                width: '100%',
                objectFit: 'cover',
                height: '100%'
            },
        },
        bottomData: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        divider: {
            borderBottom: '2px solid black',
            width: {
                xs: '17rem',
                sm: '27rem',
                md: '36rem'
            },
            m: '3.5rem auto 0 auto',
            mb: {
                xs: '1.5rem',
                sm: '2rem'
            }
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        iconBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        icons: {
            color: 'black',
            opacity: 1,
            transition: {
                xs: 'none',
                sm: '.5s'
            },
            fontSize: {
                xs: '1.5rem',
                sm: '2rem'
            },
            m: {
                xs: '0 1.25rem',
                sm: '0 2rem'
            },
            '&:hover': {
                transition: {
                    xs: 'none',
                    sm: '.5s'
                },
                color: theme.palette.secondary.main,
            }
        },
        social: {
            color: theme.palette.primary.black,
            textTransform: "uppercase",
            fontSize: '.65rem'
        },
        tag: {
            mt: '5.5rem',
            fontSize: '2.5rem!important',
            fontWeight: 800
        }
    };

    return (
        <>
            <Box sx={styles.titleBox}>
                <Typography variant="h2" sx={styles.title} >
                    {contentData.title}
                </Typography>
                <CardMedia component="img" image={tire} sx={styles.tire} alt="Símbolo Progress em cor verde" />
            </Box>
            <Typography variant="body1" sx={styles.body1}>
                {contentData.description}
            </Typography>
            <Grid container spacing={1} sx={styles.grid}>
                {
                    feedList.map(obj => {
                        return (
                            <Grid key={obj.id} item xs={6} sm={4} md={2}>
                                <Link href={obj.permalink} target="_blank" aria-label="Post do instagram">
                                    {
                                        obj.media_type === "VIDEO" ?
                                            <LazyLoadImage className="instagram-image" effect="blur" src={obj.thumbnail_url} alt="Post do instagram" placeholderSrc={contentData.placeholder} />
                                            :
                                            <LazyLoadImage className="instagram-image" effect="blur" src={obj.media_url} alt="Post do instagram" placeholderSrc={contentData.placeholder} />
                                    }
                                </Link>
                            </Grid>
                        )
                    })}
            </Grid>
            <Box sx={styles.bottomData}>
                <Box sx={styles.divider} />
                <Box sx={styles.iconContainer}>
                    {
                        contactData.map((obj, index) => {
                            return (
                                <Box key={index} sx={styles.iconBox}>
                                    <IconButton href={obj.url} target="_blank" aria-label={obj.title} sx={styles.icons}>
                                        <FontAwesomeIcon icon={obj.icon} />
                                    </IconButton>
                                    <Typography variant="body3" sx={styles.social}>
                                        {obj.title}
                                    </Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
                <Link href="mailto:assessoria@hp21.com.br" mt={'1.5rem'} sx={{ textDecoration: 'none' }}>
                    <Typography color={'black'} variant="body2">
                        assessoria@hp21.com.br
                    </Typography>
                </Link>
                <Link href="mailto:contato@hp21.com.br" mt={'1.5rem'} sx={{ textDecoration: 'none' }}>
                    <Typography color={'black'} variant="body2">
                        contato@hp21.com.br
                    </Typography>
                </Link>
                <Typography color={'black'} sx={styles.tag}>
                    #HP21
                </Typography>
            </Box>
        </>
    )
};