import Class from "../components/home/Class";
import Coaching from "../components/home/Coaching";
import Content from "../components/home/Content";
import Faq from "../components/home/Faq";
import Feedbacks from "../components/home/Feedbacks";
import Instagram from "../components/home/Instagram";
import Progress from "../components/home/Progress";
import Teacher from "../components/home/Teacher";
import Welcome from "../components/home/Welcome";

const homelist = [
    {
        content: <Welcome />
    },
    {
        content: <Class />
    },
    {
        content: <Content />
    },
    {
        opposite: true,
        content: <Faq />
    },
    {
        content: <Progress />
    },
    {
        content: <Feedbacks />
    },
    {
        content: <Coaching />
    },
    {
        content: <Teacher />
    },
    {
        opposite: true,
        content: <Instagram />
    }
];

export default homelist;